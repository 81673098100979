import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
export const billingMixins = {
    data() {
        return {
            config: useAppConfig(),
            disableLoading: '',
        };
    },

    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        updatePackage(uuid, status, params) {
            return new Promise((resolve, reject) => {
                this.$useJwt
                    .updatePackages(uuid, status, params)
                    .then(res => {
                        this.popupMsg(
                            this.$t('Message.Success'),
                            res.data[this.message],
                            'CheckIcon',
                            'success'
                        );
                        resolve(true);
                    })
                    .catch(error => {
                        if (error.response) {
                            this.popupMsg(
                                this.$t('Message.Failed'),
                                error.response.data[this.message],
                                'AlertTriangleIcon',
                                'danger'
                            );
                            reject(true);
                        }
                    });
            });
        },

        async updatePaymentPlan(value, loadingState) {
            const uuid = await this.getUuid();

            loadingState = true;
            let plan = value ? 'Quarterly' : 'Yearly';

            return new Promise((resolve, reject) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t('Message.wait'),
                        variant: 'warning',
                        text: this.$t('Message.subscriptionProcess'),
                        waiting: true,
                    },
                });
                this.$useJwt
                    .changePaymentPlan({
                        id: uuid,
                        payment_plan: {payment_plan: plan},
                    })
                    .then(res => {
                        this.popupMsg(
                            this.$t('Message.Success'),
                            res.data[this.message],
                            'CheckIcon',
                            'success'
                        );
                        resolve(false);
                    });
            });
        },
    },
};
