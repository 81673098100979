var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-text',{staticClass:"text-center"},[_c('h2',{staticClass:"d-md-block d-none",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.title))}}),_c('h3',{staticClass:"d-block d-md-none",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.title))}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","sm":"6","md":"7"}},[_c('p',{staticClass:"text-center textSubtitle w-100 font-small-3 mb-0",domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.subtitle))}})])],1)],1)]),_c('b-card-text',{},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('PaymentToggler',{attrs:{"fontSize":{fontSize: '28px'},"updateCurrentPayment":_vm.updateCurrentPayment},on:{"resetProps":function($event){_vm.updateCurrentPayment = ''},"updateBtnCurrentPackage":function($event){_vm.updateCurrentBtn = $event},"monthly":function($event){return _vm.changePaymentPlan($event)}}})],1),_c('b-row',{staticClass:"mt-3"},[(_vm.packages.length == 0)?_c('Loading'):_vm._l((_vm.packages),function(data,index){return _c('b-col',{key:index,staticClass:"p-1",attrs:{"xl":"3","sm":"6"}},[_c('b-card',{staticClass:"shadow h-100 border border-secondaryColor rounded-lg"},[_c('b-card-text',[_c('b-row',[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-0 text-uppercase font-weight-bolder",staticStyle:{"letter-spacing":"2px"},domProps:{"textContent":_vm._s(
                                                data.package.package_name
                                            )}}),(data.currentPlan)?_c('img',{staticClass:"checkLargeIcon",attrs:{"src":require("@/assets/icon/checkIcon.png")}}):_vm._e()]),_c('b-col',{staticClass:"d-flex mt-2 flex-column",staticStyle:{"height":"168px"},attrs:{"cols":"12"}},[_vm._l((data[_vm.features]),function(featuresItem,index){return _c('div',{key:index,staticClass:"d-flex h-100",staticStyle:{"gap":"1rem"}},[_c('img',{attrs:{"src":require("@/assets/icon/checkIcon.png"),"width":"20px","height":"20px"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0 font-weight-bold",domProps:{"textContent":_vm._s(featuresItem)}})])])}),_c('div',{staticClass:"dropdown-divider mt-2"})],2),_c('b-col',{staticClass:"my-5",attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bolder d-flex align-items-center justify-content-center text-center mb-0 price"},[_vm._v(" "+_vm._s(_vm.getPrice(data))+" "),_c('p',{staticClass:"mb-0"},[_vm._v("SEK")])]),_c('div',{staticClass:"font-weight-light d-flex justify-content-center priceDuration"},[_vm._v(" /"+_vm._s(_vm.$t(_vm.locale.month))+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[(data.currentPlan)?[(_vm.updateCurrentBtn)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],attrs:{"disabled":_vm.notOwnerRole,"block":"","variant":"secondaryColor"},on:{"click":function($event){return _vm.updatePlan(data)}}},[(
                                                        data.loadingStatus
                                                    )?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.locale.loading))+"... ")],1):_c('span',{domProps:{"textContent":_vm._s(
                                                        _vm.$t(
                                                            _vm.locale.upgradeBtn
                                                        )
                                                    )}})]):_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}}],attrs:{"disabled":"","block":"","variant":"white"}},[_c('span',{staticClass:"text-secondaryColor",domProps:{"textContent":_vm._s(
                                                        _vm.$t(
                                                            _vm.locale.currentBtn
                                                        )
                                                    )}})])]:_c('b-button',{style:(_vm.notOwnerRole
                                                    ? ''
                                                    : 'cursor: pointer'),attrs:{"variant":"secondaryColor","disabled":_vm.notOwnerRole,"block":""},on:{"click":function($event){return _vm.actionButton(data)}}},[(data.loadingStatus)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.locale.loading))+"... ")],1):_c('span',{domProps:{"textContent":_vm._s(
                                                    _vm.$t(_vm.locale.upgradeBtn)
                                                )}})])],2)],1)],1)],1)],1)}),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3","sm":"6"}},[(_vm.packages.length > 0)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"variant":"danger","block":"","disabled":_vm.notOwnerRole}},[(_vm.cancelSubscriptionStatus)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t(_vm.locale.loading))+"... ")],1):_c('span',{domProps:{"textContent":_vm._s(_vm.$t(_vm.locale.cancel))}})]):_vm._e()],1)],2)],1)],1),_c('b-modal',{ref:"modal",attrs:{"id":"modal-primary","ok-only":"","ok-variant":"primary","ok-title":_vm.$t(_vm.locale.modalConfirm),"modal-class":"modal-primary","centered":"","clickToClose":"","title":_vm.$t(_vm.locale.subscriptionCancel)},on:{"ok":_vm.validationForm}},[_c('b-card-text',[_c('ValidationObserver',{ref:"cancelForm"},[_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":_vm.$t(_vm.locale.optionsLabel),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:{
                                    'is-invalid': errors.length > 0,
                                }},[_c('v-select',{staticClass:"per-page-selector-send-method d-inline-block",attrs:{"options":_vm.$t(_vm.locale.reasonOptions),"clearable":false,"placeholder":_vm.$t(_vm.locale.optionsReason)},model:{value:(
                                        _vm.requestCancelSubscription.reasons_for_cancellation
                                    ),callback:function ($$v) {_vm.$set(_vm.requestCancelSubscription, "reasons_for_cancellation", $$v)},expression:"\n                                        requestCancelSubscription.reasons_for_cancellation\n                                    "}})],1),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})]}}])})],1),_c('b-form-group',[(
                                _vm.requestCancelSubscription.reasons_for_cancellation ==
                                _vm.$t(_vm.locale.reasonOptions)[0]
                            )?_c('ValidationProvider',{attrs:{"name":_vm.$t(_vm.locale.reasonText),"vid":"reasonText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":_vm.$t(_vm.locale.reasonText),"rows":"3"},model:{value:(
                                    _vm.requestCancelSubscription.cancellation_text
                                ),callback:function ($$v) {_vm.$set(_vm.requestCancelSubscription, "cancellation_text", $$v)},expression:"\n                                    requestCancelSubscription.cancellation_text\n                                "}}),_c('small',{staticClass:"text-danger",domProps:{"textContent":_vm._s(errors[0])}})]}}],null,false,2314429252)}):_vm._e()],1)],1)],1)],1)],1),_c('ModalEditMember',{attrs:{"limitUser":_vm.limitUser,"limitResource":_vm.limitResource},on:{"modalEditElement":function($event){_vm.modalEditElement = $event},"finalStepsUpdate":_vm.finalStepsUpdate,"selectedUsers":function($event){_vm.selectedUsers = $event},"usersOptions":function($event){_vm.usersOptions = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }