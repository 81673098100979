<template>
    <div class="d-flex mt-2" style="gap: 0.5rem">
        <p class="mb-0 px-sm-1 pl-2 font-weight-bold" :style="fontSize">
            {{ $t('SubscriptionCard.annually') }}
        </p>

        <div class="">
            <switches
                v-model="monthly"
                type-bold="false"
                theme="custom"
                color="green"
                :emit-on-mount="false"
                @input="changePlanPayment"
            ></switches>
        </div>
        <p class="mb-0 px-sm-1 pr-2 font-weight-bold" :style="fontSize">
            {{ $t('SubscriptionCard.monthly') }}
        </p>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import Ripple from 'vue-ripple-directive';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    name: 'PaymentToggler',
    mixins: [getSubscribersMixins],
    props: {
        fontSize: Object,
        updateCurrentPayment: {
            type: String,
        },
    },
    directives: {
        Ripple,
    },
    components: {
        Switches,
    },

    data() {
        return {
            monthly: '',
            currentMonthly: '',
            config: useAppConfig(),
        };
    },
    watch: {
        updateCurrentPayment(value) {
            this.getPaymentStatus();
        },
    },
    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        async changePlanPayment(value) {
            if (JSON.parse(this.currentMonthly) == value) {
                this.$emit('updateBtnCurrentPackage', false);
            } else this.$emit('updateBtnCurrentPackage', true);

            this.$emit('monthly', {
                monthly: value,
                paymentStatusChange: true,
            });
        },
        async getPaymentStatus() {
            const getPlanName = await this.getPaymentPlan();

            if (getPlanName[0] == 'Yearly') {
                this.currentMonthly = JSON.stringify(false);
                this.monthly = false;
            } else {
                this.currentMonthly = JSON.stringify(true);
                this.monthly = true;
            }

            this.$emit('resetProps', '');

            this.$emit('monthly', {monthly: this.monthly});
        },
    },

    mounted() {
        this.getPaymentStatus();
    },
};
</script>

<style></style>
