<template>
    <div>
        <b-card>
            <b-card-text class="text-center">
                <h2 class="d-md-block d-none" v-text="$t(locale.title)" />

                <h3 class="d-block d-md-none" v-text="$t(locale.title)" />

                <div class="d-flex justify-content-center">
                    <b-row align-h="center">
                        <b-col cols="12" sm="6" md="7">
                            <p
                                class="text-center textSubtitle w-100 font-small-3 mb-0"
                                v-text="$t(locale.subtitle)"
                            />
                        </b-col>
                    </b-row>
                </div>
            </b-card-text>
            <b-card-text class="">
                <div class="d-flex justify-content-center align-items-center">
                    <PaymentToggler
                        @resetProps="updateCurrentPayment = ''"
                        @updateBtnCurrentPackage="updateCurrentBtn = $event"
                        @monthly="changePaymentPlan($event)"
                        :fontSize="{fontSize: '28px'}"
                        :updateCurrentPayment="updateCurrentPayment"
                    />
                </div>

                <b-row class="mt-3">
                    <Loading v-if="packages.length == 0" />

                    <template v-else>
                        <b-col
                            v-for="(data, index) in packages"
                            :key="index"
                            xl="3"
                            sm="6"
                            class="p-1"
                        >
                            <b-card
                                class="shadow h-100 border border-secondaryColor rounded-lg"
                            >
                                <b-card-text>
                                    <b-row>
                                        <b-col cols="12" class="d-flex">
                                            <h2
                                                class="mb-0 text-uppercase font-weight-bolder"
                                                style="letter-spacing: 2px"
                                                v-text="
                                                    data.package.package_name
                                                "
                                            />

                                            <img
                                                v-if="data.currentPlan"
                                                class="checkLargeIcon"
                                                src="@/assets/icon/checkIcon.png"
                                            />
                                        </b-col>

                                        <b-col
                                            cols="12"
                                            class="d-flex mt-2 flex-column"
                                            style="height: 168px"
                                        >
                                            <div
                                                v-for="(
                                                    featuresItem, index
                                                ) in data[features]"
                                                :key="index"
                                                class="d-flex h-100"
                                                style="gap: 1rem"
                                            >
                                                <img
                                                    src="@/assets/icon/checkIcon.png"
                                                    width="20px"
                                                    height="20px"
                                                />

                                                <div class="d-flex flex-column">
                                                    <p
                                                        class="mb-0 font-weight-bold"
                                                        v-text="featuresItem"
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                class="dropdown-divider mt-2"
                                            />
                                        </b-col>

                                        <b-col cols="12" class="my-5">
                                            <div
                                                class="font-weight-bolder d-flex align-items-center justify-content-center text-center mb-0 price"
                                            >
                                                {{ getPrice(data) }}
                                                <p class="mb-0">SEK</p>
                                            </div>
                                            <div
                                                class="font-weight-light d-flex justify-content-center priceDuration"
                                            >
                                                /{{ $t(locale.month) }}
                                            </div>
                                        </b-col>

                                        <b-col cols="12">
                                            <template v-if="data.currentPlan">
                                                <b-button
                                                    v-if="updateCurrentBtn"
                                                    v-ripple.400
                                                    :disabled="notOwnerRole"
                                                    @click="updatePlan(data)"
                                                    block
                                                    variant="secondaryColor"
                                                >
                                                    <span
                                                        v-if="
                                                            data.loadingStatus
                                                        "
                                                    >
                                                        <b-spinner
                                                            small
                                                            type="grow"
                                                        />
                                                        {{
                                                            $t(locale.loading)
                                                        }}...
                                                    </span>

                                                    <span
                                                        v-else
                                                        v-text="
                                                            $t(
                                                                locale.upgradeBtn
                                                            )
                                                        "
                                                    />
                                                </b-button>

                                                <b-button
                                                    v-else
                                                    v-ripple.400
                                                    disabled
                                                    block
                                                    variant="white"
                                                >
                                                    <span
                                                        class="text-secondaryColor"
                                                        v-text="
                                                            $t(
                                                                locale.currentBtn
                                                            )
                                                        "
                                                    />
                                                </b-button>
                                            </template>

                                            <b-button
                                                v-else
                                                @click="actionButton(data)"
                                                variant="secondaryColor"
                                                :style="
                                                    notOwnerRole
                                                        ? ''
                                                        : 'cursor: pointer'
                                                "
                                                :disabled="notOwnerRole"
                                                block
                                            >
                                                <span v-if="data.loadingStatus">
                                                    <b-spinner
                                                        small
                                                        type="grow"
                                                    />
                                                    {{ $t(locale.loading) }}...
                                                </span>

                                                <span
                                                    v-else
                                                    v-text="
                                                        $t(locale.upgradeBtn)
                                                    "
                                                />
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-card-text>
                            </b-card>
                        </b-col>
                    </template>

                    <b-col cols="12" md="6" lg="3" sm="6">
                        <b-button
                            v-if="packages.length > 0"
                            v-ripple.400
                            variant="danger"
                            block
                            :disabled="notOwnerRole"
                            v-b-modal.modal-primary
                        >
                            <span v-if="cancelSubscriptionStatus">
                                <b-spinner small type="grow"></b-spinner>
                                {{ $t(locale.loading) }}...
                            </span>

                            <span v-else v-text="$t(locale.cancel)" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-modal
            id="modal-primary"
            ok-only
            ref="modal"
            ok-variant="primary"
            :ok-title="$t(locale.modalConfirm)"
            modal-class="modal-primary"
            centered
            @ok="validationForm"
            clickToClose
            :title="$t(locale.subscriptionCancel)"
        >
            <b-card-text>
                <ValidationObserver ref="cancelForm">
                    <b-form class="auth-login-form mt-2">
                        <!-- Reason Options -->
                        <b-form-group>
                            <ValidationProvider
                                #default="{errors}"
                                :name="$t(locale.optionsLabel)"
                                rules="required"
                            >
                                <b-input-group
                                    class="input-group-merge"
                                    :class="{
                                        'is-invalid': errors.length > 0,
                                    }"
                                >
                                    <v-select
                                        :options="$t(locale.reasonOptions)"
                                        :clearable="false"
                                        :placeholder="$t(locale.optionsReason)"
                                        class="per-page-selector-send-method d-inline-block"
                                        v-model="
                                            requestCancelSubscription.reasons_for_cancellation
                                        "
                                    >
                                    </v-select>
                                </b-input-group>
                                <small class="text-danger" v-text="errors[0]" />
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group>
                            <ValidationProvider
                                #default="{errors}"
                                :name="$t(locale.reasonText)"
                                vid="reasonText"
                                rules="required"
                                v-if="
                                    requestCancelSubscription.reasons_for_cancellation ==
                                    $t(locale.reasonOptions)[0]
                                "
                            >
                                <b-form-textarea
                                    id="textarea-default"
                                    v-model="
                                        requestCancelSubscription.cancellation_text
                                    "
                                    :placeholder="$t(locale.reasonText)"
                                    rows="3"
                                />
                                <small class="text-danger" v-text="errors[0]" />
                            </ValidationProvider>
                        </b-form-group>
                    </b-form>
                </ValidationObserver>
            </b-card-text>
        </b-modal>

        <ModalEditMember
            @modalEditElement="modalEditElement = $event"
            @finalStepsUpdate="finalStepsUpdate"
            @selectedUsers="selectedUsers = $event"
            @usersOptions="usersOptions = $event"
            :limitUser="limitUser"
            :limitResource="limitResource"
        />
    </div>
</template>

<script>
import Switches from 'vue-switches';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import {billingMixins} from '@/mixins/billingMixins';
import useAppConfig from '@core/app-config/useAppConfig';
import PaymentToggler from '@/@core/components/subscription/PaymentToggler.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import ModalEditMember from '@/@core/components/subscription/ModalEditMember.vue';

export default {
    mixins: [getSubscribersMixins, billingMixins],

    data() {
        return {
            packages: [],
            paymenToggler: true,
            currentPackage: '',
            monthly: '',
            updateCurrentBtn: false,
            tierList: [],
            modalEditElement: '',
            editPackages: '',
            cancelSubscriptionStatus: false,
            config: useAppConfig(),
            paymentStatusChange: false,
            limitResource: null,
            limitUser: null,
            updateCurrentPayment: '',
            selectedUsers: [],
            usersOptions: [],
            totalUsers: '',
            notOwnerRole: true,
            requestCancelSubscription: {
                cancellation_text: '',
                reasons_for_cancellation: '',
            },
            locale: {
                title: 'SubscriptionCard.title',
                subtitle: 'SubscriptionCard.subtitle',
                month: 'Message.month',
                upgradeBtn: 'SubscriptionCard.upgradeNow',
                loading: 'Message.loading',
                cancel: 'SubscriptionCard.cancel',
                subscriptionCancel: 'SubscriptionCard.cancel',
                modalConfirm: 'SubscriptionCard.ModalConfirm',
                optionsLabel: 'SubscriptionCard.ReasonOptionLabel',
                optionsReason: 'SubscriptionCard.ReasonOption',
                reasonText: 'SubscriptionCard.ReasonTextLabel',
                currentBtn: 'SubscriptionCard.currentPackage',
                upgradeMessage: 'SubscriptionCard.upgradeMessage',
                downgradeMessage: 'SubscriptionCard.downgrade',
                reasonOptions: 'SubscriptionCard.ReasonOptions',
            },
        };
    },

    components: {
        Switches,
        ToastificationContent,
        PaymentToggler,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        ModalEditMember,
    },

    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        features() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_features' : 'features';
            } else return this.getLang == 'sv' ? 'swe_features' : 'features';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        async getTier() {
            return this.$useJwt.tierList().then(res => res.data.results);
        },
        async getAllPackages() {
            let currentPlanId = await this.getCurrentPlan();
            let tierList = await this.getTier();

            this.packages = [];
            this.$useJwt.packages().then(res => {
                const {results} = res.data;

                results.forEach(element => {
                    if (element.package) {
                        this.packages.push({
                            ...element,
                            loadingStatus: false,
                            tier: tierList.find(
                                tierItem =>
                                    tierItem.tier.level == element.package.level
                            ),
                            currentPlan:
                                element.package.id == currentPlanId[0]
                                    ? true
                                    : false,
                        });
                    }
                });

                this.packages.sort((a, b) => {
                    return (
                        a.quarterly_base_price_monthly_average -
                        b.quarterly_base_price_monthly_average
                    );
                });

                this.currentPackage = this.packages.find(
                    element => element.currentPlan == true
                );
            });
        },

        async cancelSubscription() {
            this.cancelSubscriptionStatus = true;
            const uuid = await this.getUuid();
            let obj = {
                cancellation_text:
                    this.requestCancelSubscription.cancellation_text,
                reasons_for_cancellation: [
                    this.requestCancelSubscription.reasons_for_cancellation,
                ],
            };

            this.$useJwt
                .cancelSubscription(uuid, obj)
                .then(res => {
                    this.$nextTick(() => {
                        this.$refs['modal'].toggle('#toggle-btn');
                    });
                    //Change to modalMsg
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Message.Success'),
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data[this.message],
                        },
                    });

                    this.$refs.cancelForm.reset();

                    this.requestCancelSubscription = Object.assign({
                        cancellation_text: '',
                        reasons_for_cancellation: [],
                    });
                })
                .finally(() => {
                    this.cancelSubscriptionStatus = false;
                });
        },

        getPrice(price) {
            return this.monthly
                ? price.quarterly_base_price_monthly_average
                : price.yearly_base_price_monthly_average;
        },

        validationForm(event) {
            event.preventDefault();

            this.$refs.cancelForm.validate().then(res => {
                if (res) this.cancelSubscription();
            });
        },

        updatePlan(value) {
            if (this.paymentStatusChange) {
                value.loadingStatus = true;
                this.updatePaymentPlan(this.monthly, this.editPackages).then(
                    res => {
                        this.updateCurrentBtn = res;
                        this.updateCurrentPayment = 'update';

                        value.loadingStatus = false;
                    }
                );
            }
        },

        deletedUser() {
            this.selectedUsers.forEach(uuid => {
                this.$useJwt.deleteUser(uuid.value);
            });
            this.modalEditElement.hide();
        },

        async finalStepsUpdate() {
            let newPrice =
                this.editPackages.quarterly_base_price_monthly_average;
            let currentPrice =
                this.currentPackage.quarterly_base_price_monthly_average;

            const {package_name} = this.editPackages.package;
            const uuid = await this.getUuid();

            if (this.paymentStatusChange) {
                this.updatePaymentPlan(this.monthly, this.editPackages).then(
                    res => {
                        this.editPackages.loadingStatus = res;
                    }
                );
            }

            if (newPrice > currentPrice) {
                this.updatePackage(uuid, 'upgrade', {
                    package: package_name,
                }).then(res => {
                    if (this.selectedUsers.length > 0) {
                        this.deletedUser();
                    }
                    this.editPackages.loadingStatus = false;
                });
            } else {
                this.updatePackage(uuid, 'downgrade', {
                    package: package_name,
                }).then(res => {
                    if (this.selectedUsers.length > 0) {
                        this.deletedUser();
                    }
                    this.editPackages.loadingStatus = false;
                });
            }
        },

        async actionButton(value) {
            value.loadingStatus = true;
            const downgradeId = await this.getDowngradePackages();
            const upgradeId = await this.getUpgradePackages();

            if (downgradeId) {
                let requestDowngradePackage = this.packages.find(
                    element => element.package.id == downgradeId
                ).package.package_name;

                this.popupMsg(
                    this.$t('Message.Failed'),
                    this.$t(this.locale.downgradeMessage, {
                        package: requestDowngradePackage,
                    }),
                    'AlertTriangleIcon',
                    'danger'
                );

                value.loadingStatus = false;
            } else if (upgradeId) {
                let requestUpgrade = this.packages.find(
                    element => element.package.id == upgradeId
                ).package.package_name;

                this.popupMsg(
                    this.$t('Message.Failed'),
                    this.$t(this.locale.upgradeMessage, {
                        package: requestUpgrade,
                    }),
                    'AlertTriangleIcon',
                    'danger'
                );

                value.loadingStatus = false;
            } else if (this.totalUsers > value.number_of_allowed_users) {
                this.$bvModal.show('change-modal-users');
                this.editPackages = value;
                this.limitUser = value.number_of_allowed_users;
            } else {
                this.editPackages = value;
                this.finalStepsUpdate();
            }
        },

        changePaymentPlan(value) {
            if (value.hasOwnProperty('paymentStatusChange')) {
                this.monthly = value.monthly;

                this.paymentStatusChange = value.paymentStatusChange;
            } else this.monthly = value.monthly;
        },
    },

    async mounted() {
        this.$useJwt.getCurrentUsers().then(res => {
            const {role} = res.data.data;
            if (role !== 'Owner') {
                this.$swal({
                    text: this.$t('SubscriptionCard.notOwnerAlert'),
                    icon: 'warning',
                    showCancelButton: false,
                    showConfirmButton: true,
                    timer: 5000,
                    reverseButtons: true,
                    confirmButtonText: 'Ok',
                    timerProgressBar: true,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-danger mr-1',
                    },
                    buttonsStyling: false,
                });
            } else this.notOwnerRole = false;
        });

        this.$useJwt.getUsers().then(res => {
            this.totalUsers = res.data.data.length;
        });
        this.getAllPackages();
    },
};
</script>

<style scoped lang="scss">
.checkLargeIcon {
    position: absolute;
    bottom: 15px;
    right: 0px;
    width: 25px;
}

.price {
    font-size: 40px;
    height: 60px;
    gap: 0.5rem;
}

.priceDuration {
    font-size: 20px;
}

.per-page-selector-send-method {
    width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
